import React from 'react'
import { Span } from './elements'
import './deletable-item.scss'

export default props => {
  const {value, title, readonly=false, onDelete} = props
  return <>
    <div className="deletable">
      <div className="item">
        <span title={title}>{value}</span>
      { onDelete && !readonly &&
        <Span className="deletableItemIcon fa fa-close delete" onClick={onDelete}></Span>
      }
      </div>
    </div>
  </>
}


