import React from 'react'
import { Div } from './elements'
import './paginate2.scss'

const paginate2 = props => {
  const { numOfperPage, numOfTotal, pageNumber, pageCount, pageMore, onPrevPage, onNextPage } = props
  console.log('paginate.render')
  return <div className='paginate2'>
    {!numOfTotal ? 
    <div className='pageNo'>Page: {pageNumber}</div>
    :
    <div className='pageNo'>{pageNumber>0 ? `${(pageNumber - 1) * numOfperPage + 1}-${Math.min(numOfTotal, pageNumber * numOfperPage)} of ${numOfTotal}`: ''}</div>
    }
    <div className='pageCtrl'>
    {pageNumber > 1 ? 
      <Div className='clickable-div' onClick={onPrevPage}>Prev</Div>
      :
      <div className='disable-div'>Prev</div>
    }
    {( pageNumber < pageCount || (pageNumber === pageCount && pageMore)) ?
      <Div className='clickable-div' onClick={onNextPage}>Next</Div>
      :
      <div className='disable-div'>Next</div>
    }
    </div>
  </div>
}
paginate2.defaultProps = {
  pageNumber: 0,
  pageCount: 0,
  pageMore: false,
  onPrevPage: ()=>{},
  onNextPage: ()=>{}
}
export default paginate2