import React, { useState, useEffect } from 'react'
import './tree.scss';
import { Key } from '../../../utils'

export default props => {
  const { item, selected, nodeLabel, children, getId=()=>{}, initCollapsed, expandId=-1, headerStyle = {}, headerTitleStyle = {}, onCollapse=()=>{}, onKeyDown = () => { }, onFocus =()=>{}, onBlur=()=>{}, nodeSelected = () => { } } = props
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => { 
    setCollapsed(initCollapsed && (!item || getId(item) !== expandId))
  }, [initCollapsed, expandId])

  const _onKeyDown = (event, item) => {
    onKeyDown(event, item)

    if (event.keyCode === Key.RIGHT_ARROW && collapsed) {   
      setCollapsed(false)
    } else if (event.keyCode === Key.LEFT_ARROW && !collapsed) {   
      setCollapsed(true)
    } else if (event.keyCode === Key.ENTER) {
      setCollapsed(!collapsed)
    }
  }

  const onClick = (event, item) => {
    nodeSelected(event, item)
    collapse(!collapsed)
  }

  const collapse = c => {
    setCollapsed(c)
    onCollapse(c)
  }

  return (
    <div className="tree-node row">
      <div className={`tree-header tree-item tms-control ${selected ? 'selected': ''}`} 
          style={headerStyle}
          role="treeitem"
          aria-expanded={!collapsed}
          aria-selected={selected == item}
          tabIndex="0"
          onKeyDown={event => _onKeyDown(event, item)}
          onClick={event => onClick(event, item)} 
          onFocus={event => onFocus(event, item)}
          onBlur={event => onBlur(event, item)}>
        <span className={`collapsed ${!collapsed ? 'expanded' : ''}`} onClick={() => collapse(!collapsed)}></span>
        <div style={{'width': '100%', ...headerTitleStyle}}>
          {nodeLabel}
        </div>
      </div>
      <div className={'children' + (collapsed ? ' collapsed' : '')}>
        {collapsed ? null : children}
      </div>
    </div>
  )
}



