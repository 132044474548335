import React, { useState, useEffect } from 'react'
import { Div, DropDownMenu, Span } from '../common'
import { getLastBpsTenant, setLastBpsTenant, tryParseJSON, stringifyJSON } from '../../utils'
import ShowBox from './show-box'
import './landing.scss'

const minstries =  {id: 'cddc1229-ac2a-4b97-b78a-0e5cacb5865c', text: 'Ministries and clusters', src: 'https://www.sts2.gov.on.ca/adfs/portal/images/idp/localsts.png?id=A3911892BE04D81EBA5A8E0C74F77099AA1DB05E542FBFCC78C9DF4B0EC0E3A6'}
const commons =  {id: 'common', text: 'Ontario Financing Authority (OFA)', src: 'https://www.sts2.gov.on.ca/adfs/portal/images/idp/idp.png?id=3EADD3E829A20DF612C7A77960FF811E66E3EE6BAE2C33C9B20E7478BAC87548'}
const agencies =  {id: 'cddc1229-ac2a-4b97-b78a-0e5cacb5865c', text: 'Agencies, Boards and Commissions', src: 'https://www.sts2.gov.on.ca/adfs/portal/images/idp/idp.png?id=3EADD3E829A20DF612C7A77960FF811E66E3EE6BAE2C33C9B20E7478BAC87548'}

const header = { text: 'Sign in to BPS eArchives with one of these accounts:', src: 'https://www.sts2.gov.on.ca/adfs/portal/logo/logo.png?id=44CE63845CDB1B2570DCA1F3295D80463A7456C9F94C9994FF5FE5DEDB10729C&rp=003ec01a-9862-e711-80e1-00155d164614'}
      
export default props => {
  const { tenants, onSelect=()=>{} } = props
  const [ bpsTenant, setBpsTenant ] = useState({})
  useEffect(() => { 
    setBpsTenant(tryParseJSON(getLastBpsTenant()) || tenants[0])
  }, [tenants])

  const onClick = tenantId => {
    setLastBpsTenant(stringifyJSON(bpsTenant))
    onSelect(tenantId, bpsTenant)
  }
  
  return (
    <div className='landing'><div className='auth'><div className='show-area'>
      <ShowBox showBody={<>
        <img className='banner-logo' role='presentation' data-bind='attr: { src: bannerLogoUrl }' src={header.src}/>
        <div className='row'>{header.text}</div>

        <Div className='row go' onClick={()=>onClick(minstries.id)}>
          <img className='largeIcon' src={minstries.src}/>
          <span className='largeTextNoWrap indentNonCollapsible'>{minstries.text}</span>              
        </Div>
        
        <Div className='row go'>
          <img className='largeIcon' src={commons.src}/>
          <span className='largeTextNoWrap indentNonCollapsible common'>
            <DropDownMenu scrollable className='drop-down-tenants'
              display={<Div className='display'>
                <span className='label'>{bpsTenant.label}</span>
                <span className='fa fa-angle-down'/>
              </Div>}>
            {
              tenants.map((e, i)=><Div skipFocus className='dropdown-item' key={i} onClick={()=>setBpsTenant(e)}>{e.label}</Div>)
            }
            </DropDownMenu>
          </span>
          <Span className='fa fa-arrow-circle-right' onClick={()=>onClick(commons.id)}/>          
        </Div>

        <Div className='row go' onClick={()=>onClick(agencies.id)}>
          <img className='largeIcon' src={agencies.src}/>
          <span className='largeTextNoWrap indentNonCollapsible'>{agencies.text}</span>              
        </Div>
        
        {/* <div className='row' style={{justifyContent: 'flex-end'}}><button className='tms-button primary' onClick={onClick}>OK</button></div> */}
      </>}/>
    </div></div></div>
  )
}