import React, { useState, useRef, forwardRef, useImperativeHandle} from 'react'
import './dropdown.scss'
import ResizeDetector from './resize-detector'
import Scrollbar from './scroll-bar'

export default forwardRef((props, ref) =>  {
  const {id, style, menuStyle, display, children, tabbable=true, scrollable=false,
    onDropMenuShow=()=>{}, 
    onDropMenuHide=()=>{}, 
    onKeyDown=()=>{},
    onClick=()=>{}
  }=props
  
  const [toggleOn, setToggleOn] = useState(false)
  const [menuHeight, setMenuHeight] = useState(0)
  const wrapperRef = useRef({})
  const focusRef = useRef({})

  const handleClickOutside = event => blurDropMenu(event.target, event)

  const handleKeypress = event => {
    if(event.keyCode === 27){
      onHideDropMenu()
    }else if(event.keyCode === 9){
      setTimeout(()=>blurDropMenu(document.activeElement, event), 100)
    }else{
      handleClickOutside(event)
    }
  }

  const blurDropMenu = (target, event) => {
    if (!wrapperRef || !wrapperRef.current || wrapperRef.current.contains(target) || clickedOnScrollbar(event)) return
    onHideDropMenu(false)
  }

  const clickedOnScrollbar = event => {
    if( event && event.offsetY > event.target.clientHeight || event.offsetY == 0 ){
      return true;
    }
  } 

  useImperativeHandle(ref, () => ({
		showDropMenu(e){ onShowDropMenu(e) },
    hideDropMenu(e){ onHideDropMenu(e) },
    isToggleOn(){ return toggleOn }
  }))
  
  const onShowDropMenu = () =>{
    setToggleOn(true)
    onDropMenuShow()
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeypress)
  }

  const onHideDropMenu = (restoreFocus = true) =>{
    setToggleOn(false)
    onDropMenuHide()
    if(restoreFocus){
      focusRef.current.focus && focusRef.current.focus()
    }
    document.removeEventListener('mousedown', handleClickOutside)
    document.removeEventListener('keydown', handleKeypress)
  }

  const onResize = (w,h) => setMenuHeight(h + 27)

  return (
    <div id={id} className='custom-dropdown' role='menu' ref={wrapperRef} style={{...style}} onClick={onClick} onKeyDown={onKeyDown}>
      <span className={`dropdown ${scrollable ? 'scrollable' : ''}`}>
      {
        display && <div className='dropdown-display' ref={focusRef} tabIndex={tabbable? '0': '-1'}>{display}</div>
      }
        <div className={`dropdown-menu ${toggleOn && 'show'}`} 
          style={scrollable ? {...menuStyle, height: `${menuHeight}px`}: menuStyle}>
           { !scrollable ? children :
            <Scrollbar><div style={{display: 'flex', flexDirection: 'column'}}>
              { children }
              <ResizeDetector onResize={onResize}/>
              </div>
            </Scrollbar>
          }
        </div>
      </span>
    </div>
  )
})