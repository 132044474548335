
import React from 'react'
export default props => {
  const {showHeader, showBody, showFooter, ...rest} = props
  return (
  <div className='show-box' {...rest}>
    <div className='row strong header'>{showHeader}</div>
    <div className='row small body'>{showBody}</div>
    {showFooter && <div className='row footer'>{showFooter}</div>
    }
  </div>  
  )
}