import React, { Component } from 'react'
import Scrollbar from 'react-custom-scrollbars'
import PressHold from './press-hold'

import './scroll-bar.scss'
export default class Scrollbar2 extends Component {  
  constructor(props, ...rest) {
    super(props, ...rest)
    this.state = { showBar: false, status: true }
    this.scrollRef = React.createRef();
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    
    this.onScrollUp = this.onScrollUp.bind(this)
    this.onScrollDown = this.onScrollDown.bind(this)
  }

  setStatus(status){
    this.setState({ status: status })
  }

  handleUpdate(values) {
    const { scrollHeight, clientHeight } = values
    this.setState({ showBar: scrollHeight > clientHeight })
  }

  handleScroll() {
    this.props.onScroll && this.state.status && this.props.onScroll(this.scrollRef.current.getValues())
  }

  onScrollUp(){
    const current = this.scrollRef.current
    current.scrollTop(current.getScrollTop() - 40)
  }

  onScrollDown(){
    const current = this.scrollRef.current
    current.scrollTop(current.getScrollTop() + 40)
  }

  render() {
    const { showBar } = this.state
    const { styles } = this.props
    return <div className='scroll-bar' style={{...styles}} >
      {showBar && <PressHold className='fa fa-caret-up' action={this.onScrollUp}/>}
        <Scrollbar style={{position: 'relative', height: '100%', width: '100%'}} 
          ref={this.scrollRef}
          renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
          renderTrackVertical={props => <div {...props} className={`track-vertical ${showBar ? 'show' : ''}`}/>}
          renderThumbVertical={props => <div {...props} className='thumb-vertical'/>}
          {...this.props}
          onUpdate={this.handleUpdate}
          onScroll={this.handleScroll}          
          />
      {showBar && <PressHold className='fa fa-caret-down' action={this.onScrollDown}/>}
    </div>
  }
}