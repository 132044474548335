import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Div, DropDownMenu, AnimateInput } from '.'
import { debounce, newId } from '../../utils'

export default forwardRef((props, ref) => {
    const { label, labelledBy, value, extra, options = [], placeholder, onChange = () => { }, menuStyle, styles, pullRight, 
        hasCaret=false, selectReq=true, showTitle=false, blockMsg='' } = props

    const [menuId, setMenuId] = useState({})
    const [menuExpanded, setMenuExpanded] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState({})
    const [init, setInit] = useState(true)
    const [showBlockMsg, setShowBlockMsg] = useState(false)

    const inputRef = useRef({}), ddlRef = useRef({}), tmpTextRef = useRef('')

    useEffect(() => setMenuId(newId()), [])
    useEffect(() => {
        setFilteredOptions(options)
        if (!!value) {
            setSelectedOption(value)
        }
    }, [value, options])

    const api = () => ({
        setValue: v => {
            inputRef.current.setValue && inputRef.current.setValue(v.label)
            setSelectedOption(v)
        },
        getValue: () => selectedOption.id  ? selectedOption : tmpTextRef.current,
        isToggleOn: () =>ddlRef.current.isToggleOn(),
        onToggle :() => onToggle(),
        hideDropMenu: () =>ddlRef.current.hideDropMenu(),
        showDropMenu: () =>ddlRef.current.showDropMenu(),
        clear: () => {
            tmpTextRef.current = ''
            inputRef.current.value = ''
            setSelectedOption({})
        }
    })
    useImperativeHandle(ref, api)

    const onSearchStrChange = debounce(v => {
        setFilteredOptions(options.filter(o => o.label.toLowerCase().indexOf(v.trim().toLowerCase()) !== -1))
        setInit(false)
    }, 200)

    const onToggle = () => {
        if (ddlRef.current.isToggleOn()) {
            ddlRef.current.hideDropMenu()
        } else {
            ddlRef.current.showDropMenu()
        }
    }

    const onSelect = option => {
        setSelectedOption(option)
        tmpTextRef.current = ''
        ddlRef.current.hideDropMenu()
        onChange(option)
    }

    const onInput = event => {
        onChange({})
        setShowBlockMsg(false)
        setInit(true)
        tmpTextRef.current = event.target.value
        const _value = event.target.value
      
        if (_value.trim()) {
            onSearchStrChange(_value)
        } else {
            setFilteredOptions(options)
        }
        if (!!!event.target.value) {
            ddlRef.current.hideDropMenu()
            return
        }
        ddlRef.current.showDropMenu()
    }

    const onClick = event => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation();
    }
    const onBlur = event =>{      
        setTimeout(()=>{
            if (selectReq && tmpTextRef.current && !selectedOption.id && !ddlRef.current.contains(document.activeElement)) {
                // inputRef.current.focus()
                // setShowBlockMsg(true)
                tmpTextRef.current = ''
                inputRef.current.value = ''
            }
        })     
    }

    const onDropMenu = event => setMenuExpanded(event)

    return (
        <div className='typeahead'>
            <div className='row'>
                <DropDownMenu id={menuId} onBlur={onBlur} onDropMenu={onDropMenu} autoFocus={false} pullRight={pullRight} scrollable 
                    menuRole="listbox" focusable={true} menuLabelledBy={labelledBy} ref={ddlRef} style={{ width: '100%' }} spaceDown={false}
                    menuStyle={{ maxHeight: '300px', width: '150%', top: '95%', ...menuStyle }}
                    display={<>
                        <AnimateInput ref={inputRef} aria-controls={menuId} aria-haspopup="listbox" aria-expanded={menuExpanded} 
                            labelledBy={labelledBy} title={showTitle && selectedOption.label || ''} 
                            value={selectedOption.label || tmpTextRef.current || ''} style={{width: '90%'}} aria-haspopup={true}
                            label={label} onClick={onClick} onChange={onInput} placeholder={placeholder} style={styles} />
                            {!extra && hasCaret ? <span aria-hidden='true' className='fa fa-caret-down' style={{ margin: '10px 0 0 -20px', top: '-5px', color: '#98a3a6' }} onClick={onToggle} />  : extra}
                    </>}>
                    <div>
                        {
                            filteredOptions.length === 0 ? 'No data match'
                            :
                            filteredOptions.map((o, i) => <Div key={i} role="option" aria-selected={o.id===selectedOption.id} title ={showTitle && o.label} skipFocus className='dropdown-item' onClick={() => onSelect(o)}>{o.label}</Div>)
                        }
                        <div className="sr-only" role="status" aria-live="assertive" aria-atomic={true}>
                            {!init && <span>{filteredOptions.length} records found for {tmpTextRef.current}</span>}
                        </div>
                        {showBlockMsg && blockMsg && <span className='sr-only' role="alert">{blockMsg}</span>}
                    </div>
                </DropDownMenu>
            </div>
        </div>
    )
})
