import React, { useRef, forwardRef, useImperativeHandle} from 'react'
import DropDown from './dropdown-base'
import './dropdown.scss'
export default forwardRef((props, ref) =>  {
  const {children, ...rest}=props
  const wrapperRef = useRef({})

  useImperativeHandle(ref, () => ({
		showDropMenu(e){ wrapperRef.current.showDropMenu(e) },
		hideDropMenu(e){ wrapperRef.current.hideDropMenu(e) }
  }))
  
  const onKeyDown = e => {
    if(e.keyCode === 13 || e.keyCode === 32){
      onClick(e)
    }else if (e.keyCode === 40) {   // Down
      e.preventDefault()
      if(e.target.className === 'dropdown-display'){
        e.target.parentNode.querySelector('.dropdown-menu').children[0].focus()
      }else if(e.target.nextElementSibling){
        
        e.target.nextElementSibling.focus()
      }
    }else if (e.keyCode === 38) { // Up
      e.preventDefault();
      if(e.target.previousElementSibling){
        e.target.previousElementSibling.focus()
      }
    }
  }

  const onClick = e => {
    wrapperRef.current.isToggleOn() ? wrapperRef.current.hideDropMenu(e) : wrapperRef.current.showDropMenu(e)
  }

  return (
    <DropDown ref={wrapperRef} {...rest} onKeyDown={onKeyDown} onClick={onClick}>{children}</DropDown>
  )
})