import React, { useEffect, useState } from 'react'
import './tabs2.scss'
import { newId, Key } from '../../utils'

export default props => {
  const { tabs, className="tabs2", tabClassName='', hasTitle=false, describedReq=false, onChange=()=>{}, isTabEnabled=()=>true } = props
  const [active, setActive] = useState(0)
  const [tabId, setTabId] = useState()
  const [descId, setDescId] = useState()

  useEffect(() => { 
    setTabId(newId())
    setDescId(newId())
  }, [])
  // useEffect(() => { setActive(actived)}, [actived])

  const onClick = t =>{
    setActive(t.id)
    onChange(t)
  }

  const onKeyDown = (e, t, i) => {
    if (e.keyCode === Key.ENTER || e.keyCode === Key.SPACE){
      e.preventDefault()
      e.stopPropagation()
      onClick(t)
    } else if (e.keyCode === Key.RIGHT_ARROW) {   
      e.preventDefault()
      e.stopPropagation()
      if (i === tabs.length - 1) {
        e.target.parentNode.firstElementChild.focus()
      } else {
        e.target.nextElementSibling && e.target.nextElementSibling.focus()
      }
    } else if (e.keyCode === Key.LEFT_ARROW) { 
      e.preventDefault()
      e.stopPropagation()
      if (i === 0) {
        e.target.parentNode.lastElementChild.focus()
      } else {
        e.target.previousElementSibling && e.target.previousElementSibling.focus()
      }
    }
  }

  return (
    <div className="tabs2" id={tabId}>
      <span className='sr-only' id={descId}>To move to an item use arrow keys</span>
      <div className={className} role="tablist" aria-describedby={descId}>
        {tabs.map((t, i)=>{
          return isTabEnabled(t)===true ?
            <div key={i} 
              role="tab"
              id={`${tabId}_${t.id}`} 
              aria-selected={active===t.id} 
              aria-controls={`${tabId}_${t.id}_panel`}
              tabIndex={active===t.id ? '0': '-1'}
              className={`item ${tabClassName} ${active===t.id ? 'active': 'inactive'}`} 
              title={hasTitle ? t.label: ''}
              onKeyDown={e=>onKeyDown(e, t, i)}
              onClick={()=>onClick(t)}>{t.label} 
            </div>
            :
            <div key={i} 
              title={hasTitle ? t.label: ''} 
              className={`item inactive ${tabClassName}`}>{t.label}
            </div>
          }
        )}
      </div>
      {
        tabs.map((t, i)=>
          <div tabIndex="0" role="tabpanel" id={`${tabId}_${t.id}_panel`} aria-labelledby={`${tabId}_${t.id}`} 
            {...(describedReq && {'aria-describedby': `${tabId}_${t.id}_panel`})} style={{display: active===t.id ? 'block': 'none'}}>
            {t.comp}
          </div>
        )
      }
    </div>
  )
}