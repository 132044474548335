export const cleanSettings = () => {
    removeBpsTenant()
}

const bpsTenant = 'bpsTenant'
export const getBpsTenant = () => localStorage.getItem(bpsTenant)
export const setBpsTenant = tid => localStorage.setItem(bpsTenant, tid)
const removeBpsTenant = () => localStorage.removeItem(bpsTenant)

const lastBpsTenant = 'lastBpsTenant'
export const getLastBpsTenant = () => localStorage.getItem(lastBpsTenant)
export const setLastBpsTenant = tid => localStorage.setItem(lastBpsTenant, tid)

export const isBpsLanding = data => {
  return data.actionAs === 2 && !getBpsTenant()
}
