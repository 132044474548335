import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import './list.scss'
import ListItem from './list-item'
import Paginate2 from '../paginate2'
import ListHeader from './list-header'
import { jumpFocus, jumpFocus2 } from '../../../utils/nav-util'
import listItem from './list-item';

export default forwardRef((props, ref) =>  {
  const { rows, reset, numOfperPage, numOfTotal, onPageChange, onSelectRow, listHeight, style, children,
    nextFocus, escFocus, showHeader, showRefresh, cols, checkable, onCheckCol, onRefresh, bulkExport, onBulkExport, bulkExportMeta, onBulkExportMeta,
    sortCols, sortDir, onSortCol, onToggleSortDirection, getId=e=>e} = props

  const [ listCatch, setListCatch ] = useState([])
  const [ listSnap, setListSnap ] = useState([])
  const [ pageNo, setPageNo ] = useState(1)
  const [ pageCount, setPageCount ] = useState(0)
  const [ pageMore, setPageMore ] = useState(false)
  const [ activeRow, setActiveRow ] = useState(-1)

  const [ checkedItems, setCheckedItems ] = useState([])
  const [ checkedAll, setCheckedAll ] = useState(false)
  const [ lastCheck, setLastCheck ] = useState({})

  const listRef = useRef({}), shiftDownRef = useRef(false)

  useImperativeHandle(ref, () => ({
    getCheckedItems: () => { 
      return checkedItems 
    },
    clear: () => {
      setCheckedItems([])
      setCheckedAll(false)
    },
    reset: () => {
      setCheckedItems([])
      setCheckedAll(false)
      onPage(1)
      callPageChangeCallback(false)
    }
  }))

  useEffect(()=> {
    if (checkable) {
      document.addEventListener("keyup", handleKeyUp)
      document.addEventListener("keydown", handleKeyDown)
    }
    return () => {
      if (checkable) {
        document.removeEventListener("keyup", handleKeyUp)
        document.removeEventListener("keydown", handleKeyDown)
      }
    }
  }, [])

  useEffect(() => {
    setListCatch([])
    setListSnap([])
    setPageNo(0)
    setPageCount(0)
    setPageMore(false)
    setActiveRow(-1)
  }, [reset])

  useEffect(() => {
    if(rows.length === 0){
      setPageMore(false)
    }else{
      const _pageNo = pageNo + 1
      setListCatch([...listCatch, ...rows])
      setListSnap([...rows]) 
      setPageCount(pageCount + 1)
      setPageNo(pageNo + 1)
      setPageMore(numOfTotal > numOfperPage * _pageNo)
      setCheckedAll(false)
      // setCheckedItems([])
    }
  }, [rows])


  const handleKeyUp = e => {
    if (e.key === "Shift") {
      shiftDownRef.current = false
    }
  }
  
  const handleKeyDown = e => {
    if (e.key === "Shift") {
      shiftDownRef.current = true
    }
  }

  const onPage = pNo => {
    const start = numOfperPage * (pNo - 1), end = numOfperPage * pNo
    const _listSnap = []
    listCatch.slice(start, end).map(e=>_listSnap.push({...e, selected: false}))
    setListSnap(_listSnap)
    setPageNo(pNo)
  }

  const onPrevPage = () => {
    if(pageNo > 1){
      callPageChangeCallback(false)
      onPage(pageNo - 1)
      // setCheckedItems([])
      setCheckedAll(false)
    }
  }
  
  const onNextPage = () => {
    if(numOfperPage > listSnap.length){
      return
    }
    
    if(pageNo === listCatch.length/numOfperPage){
      callPageChangeCallback(true)
    }else{
      callPageChangeCallback(false)
      onPage(pageNo + 1)
    }
    setCheckedAll(false)
  }

  const callPageChangeCallback = (requestNewpage) => {
    onPageChange(requestNewpage, pageNo+1)
    listRef.current.parentNode.scrollTop = listRef.current.offsetTop - 70
  }

  const selectRow = (index, item, event) =>{
    onSelectRow(item, index)
    const _listSnap = [...listSnap]
    _listSnap.map((e, i)=>{
      e.selected = false
      return e
    })
    _listSnap[index] && (_listSnap[index].selected = true)

    setListSnap(_listSnap)
  }

  const onNavigate =(row, event) => {
    if (event.keyCode === 40) {       // Down
      event.preventDefault()
      event.stopPropagation()
      if(row < listSnap.length -1){
        setActiveRow(row + 1 )
      }else if(pageNo < pageCount || (pageNo === pageCount && pageMore)){
        setActiveRow(0)
        onNextPage()        
      }
    } else if (event.keyCode === 38 ) { // Up
      event.preventDefault()
      event.stopPropagation()
      if(row > 0){
        setActiveRow(row - 1 )
      }else if(pageNo > 1){
        setActiveRow(listSnap.length - 1)
        onPrevPage()
      }
    } else if (event.keyCode === 9 && !event.shiftKey && nextFocus) { // Tab
      if (checkable && !document.activeElement.querySelector("input")) {
        event.preventDefault()
        jumpFocus(nextFocus)
      }
    } else if (event.keyCode === 9 && event.shiftKey  && escFocus) { // Shift + Tab
      event.preventDefault()
      jumpFocus2(escFocus)
    }
  }

  const onCheckAll = on => {
    if (on) {
      setCheckedItems([...checkedItems, ...listSnap.filter(e => !checkedItems.some(t => getId(t) === getId(e)))])
    } else {
      setCheckedItems(checkedItems.filter(e => !listSnap.some(t => getId(t) === getId(e))))
    }
    setCheckedAll(on ? true: false)
  }

  const onCheck = (event, item) => {
    if (shiftDownRef.current) {   
        let startIdx = listSnap.findIndex(e => e===item)
        let endIdx = listSnap.findIndex(e => e=== lastCheck)
        let selectedArr = listSnap.slice(Math.min(startIdx, endIdx), Math.max(startIdx, endIdx)+1)
        let _checkedItems = [...checkedItems]
        if (checkedItems.some(e=>e===lastCheck)) {
          selectedArr.forEach(selected => {
            if (!_checkedItems.some(e=>e===selected)) {
              _checkedItems.push(selected)
            }
          })
        } else {
          _checkedItems = _checkedItems.filter(e => !selectedArr.some(sa => sa === e))
        }
       
        setCheckedItems(_checkedItems)
    } else {
      if (event.target.checked) {
        if (!checkedItems.some(e=>e===item)) {
            setCheckedItems([item, ...checkedItems])
        }
      } else {
          setCheckedItems(checkedItems.filter(e => e !== item))
      }

    }
    setLastCheck(item)
  }

  const styles = {background: '#fff', ...style}

  console.log("list render...")
  return (
    <div style={styles}>
      {
        showHeader && <ListHeader hasChecked={checkedItems.length>0} 
          {...{showRefresh, sortCols, sortDir, onSortCol, bulkExport, onBulkExport, bulkExportMeta, onBulkExportMeta, checkable, checkedAll, onCheckAll, onToggleSortDirection, cols, onCheckCol, onRefresh}}/>
      }
      <div ref={ref} className='list' style={{height: (listHeight-26)+ 'px' }}>
        <ul ref={listRef} >
        {
          listSnap.map((item, i) => 
            <li key={i} className={listSnap[i] && listSnap[i].selected ? 'itemSelected' : 'item'}>
              <ListItem
                onSelect={ ()=> selectRow(i, item)}
                checkable={checkable}
                checked={checkedItems.some(e => getId(e) === getId(listSnap[i]))}
                onCheck={e=>onCheck(e, listSnap[i])}
                actived={ activeRow === i }
                onNavigate={ event => onNavigate(i, event)}>
                {()=>children(item, i)}
              </ListItem>
            </li>)
        }
        </ul>
      </div>
      <Paginate2 pageCount={pageCount} pageNumber={pageNo} numOfperPage={numOfperPage} numOfTotal={numOfTotal} pageMore={pageMore} onPrevPage={onPrevPage} onNextPage={onNextPage}/>
    </div>
  )
})
