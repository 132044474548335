import React from 'react'
import './tabs.scss'

export default props => {
  const { actived, children } = props
  return <div className='tabs'>
    <ul className="nav nav-tabs">
    {
      children.map((c,i) => <li key={i} role="presentation" className={actived === i ? 'active' : ''}><span role='heading' aria-level='3'>{c}</span></li>)
    }
    </ul>
  </div>
}