import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle} from 'react'
import DropDown from './dropdown-base'
import './dropdown.scss'

export default forwardRef((props, ref) =>  {
  const {children, ...rest}=props
  const wrapperRef = useRef({})

  useImperativeHandle(ref, () => ({
		showDropMenu(e){ wrapperRef.current.showDropMenu(e) },
    hideDropMenu(e){ wrapperRef.current.hideDropMenu(e) },
    isToggleOn: () => wrapperRef.current.isToggleOn()
  }))

  const onKeyDown = e => {
    if(e.keyCode === 13 && !wrapperRef.current.isToggleOn()){
      onClick(e)
    }
  }

  const onClick = () =>{
    if(!wrapperRef.current.isToggleOn()){
      wrapperRef.current.showDropMenu()
    }
  }

  return (
    <DropDown ref={wrapperRef} {...rest} onKeyDown={onKeyDown} onClick={onClick}>
      {children}
    </DropDown>
  )
})