import React, { useState, useEffect } from 'react';
import DropDown from '../dropdown'
import { Span, Div, Input } from '../../common/elements'
import ResizeDetector from '../../common/resize-detector'

export default props => {
  console.log('ListHeader.render')
  const { onRefresh, showRefresh, cols, checkable, onCheckCol, sortCols, sortDir, onSortCol, hasChecked, bulkExport, bulkExportMeta, onBulkExport, onBulkExportMeta, checkedAll, onToggleSortDirection, onCheckAll } = props
  const getSelectedSortCol = () => sortCols.filter(s => s.selected)[0] || {}
  const [descending, setDescending] = useState(true)
  const [compress, setCompress] = useState(false)

  useEffect(()=> {
    setDescending(sortDir === 'desc' ? true: false)
  }, [sortDir])

  const onToggle = () => {
    setDescending(!descending)
    onToggleSortDirection(descending)
  }

  const onClickBulkExport = () => {
    if (hasChecked) {
      onBulkExport()
    }
  }

  const onClickBulkExportMeta = () => {
    if (hasChecked) {
      onBulkExportMeta()
    }
  }

  const onResize = (w, h) => {
    console.log("header with: " + w)
    setCompress(w < 270)
  }

  return <div className='list-header'>
    <ul>
      <li className='check-all' style={{display: 'flex'}}>
        <div style={{ width: '100%', display: 'flex', paddingLeft: '2px', alignItems: 'center'}}>
            {
              checkable ? <div style={{flex: '0 0 16px', height: '25px'}}>
                <Input type='checkbox' label="select all" onChange={e => onCheckAll(e.target.checked)} checked={checkedAll} />
              </div>
                :
              <div />
            }
            {
              showRefresh ?
                <div className='refresh' style={{flex: '0 0 16px'}} onClick={onRefresh}>
                  <span className='fa fa-refresh' aria-label='Refresh'></span>
                </div>
                :
                <div />
            }

            {sortCols && <div style={{flex: '1 1 auto', paddingLeft: '5px', height: '25px'}}>
              <Span onClick={onToggle} className="sort-cols-lbl" style={{ cursor: 'pointer'}}>
                <div style={{width: compress ? '100px' : 'inherit', display: 'inline-block', verticalAlign: 'bottom'}} className="break-out" title={`Order by ${getSelectedSortCol().label}`}> 
                  {`Order by ${getSelectedSortCol().label}`}
                </div>
                <span style={{ marginLeft: '5px', marginRight: '5px' }} className={`fa fa-arrow-down  ${!descending && 'fa-rotate-180'}`}></span>
              </Span>

              {sortCols.length > 1 &&
                <DropDown menuStyle={{padding: '10px'}} style={{display: 'inline-block'}} display={<div className="sort-cols" aria-label="Sort list by"><span className='fa fa-angle-down' /></div>}>
                  {
                    sortCols.map((col, i) =>
                      <Div key={i} className='dropdown-item' onClick={() => onSortCol(col)}>
                        {col.label || ''}
                      </Div>
                    )
                  }
                </DropDown>
              }
            </div>
            }
         
            {bulkExportMeta && <div style={{ flex: '0 0 30px', height: '25px' }}>
              <Span className={`fa fa-file-excel-o ${!hasChecked ? 'disabled': ''}`} title="Export Metadata" onClick={onClickBulkExportMeta}></Span>
            </div>}

            {bulkExport && <div style={{ flex: '0 0 30px', height: '25px' }}>
              <Span className={`fa fa-download ${!hasChecked ? 'disabled': ''}`} title="Bulk Download" onClick={onClickBulkExport}></Span>
            </div>}

            {cols &&  <div style={{ flex: '0 0 35px', height: '25px'}}>
              <DropDown
                style={{ marginRight: '10px' }}
                display={<div className='fa fa-ellipsis-v' aria-label='select column to hide' style={{ width: '15px', textAlign: 'center' }} />}
                keepShowing={true}>
                {
                  cols.map((col, i) =>
                    <div key={i} className='dropdown-item'>
                      <Input onClick={e => onCheckCol(col, e.target.checked)} label={col.label} defaultChecked={col.selected} type='checkbox' style={{marginTop: '6px'}}/>
                      {col.label || ''}
                    </div>
                  )
                }
            </DropDown>
            </div>
            }
        
        </div>
      </li>
    </ul>
    <ResizeDetector onResize={onResize}/>
  </div>
}