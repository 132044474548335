import React, { useRef, useState, useEffect, forwardRef } from 'react'
import {newId, Key} from '../../utils'
import './elements.scss'

export const Div = forwardRef((props, ref) => {
  const {children, skipFocus, ...rest} = props
  const _ref = ref || useRef({})
  return <div ref={_ref} tabIndex={skipFocus ? '-1': '0'} {...rest} onKeyDown={e => e.keyCode===13 && _ref.current.click()}>
    {children}
  </div>
})

export const Li = props =>{
  const {children, skipFocus, ...rest} = props
  const ref = useRef({})
  return <li ref={ref} tabIndex={skipFocus ? '-1': '0'} {...rest} onKeyDown={e => e.keyCode===13 && ref.current.click()}>
    {children}
  </li>
}

export const Span = props =>{
  const {children, skipFocus, ...rest} = props
  const ref = useRef({})
  return <span ref={ref} tabIndex={skipFocus ? '-1': '0'} {...rest} onKeyDown={e => e.keyCode===13 && ref.current.click()}>
    {children}
  </span>
}

export const Input = forwardRef((props, ref) => {
  const {label} = props
  const [ id, setId ] = useState('')
  useEffect(() => { setId(newId()) }, [])
  return <>
    <label className='sr-only' htmlFor={id}>{label}</label>
    <input {...props} ref={ref} id={id}/>
  </>
})

export const Input2 = forwardRef((props, ref) => {
  const {label} = props
  const [ id, setId ] = useState('')
  useEffect(() => { setId(newId()) }, [])
  return <>
    { label }
    <label className='sr-only' htmlFor={id}>{label}</label>
    <input {...props} ref={ref} id={id}/>
  </>
})

export const Textarea = forwardRef((props, ref) => {
  const {children, label, ...rest} = props
  const [ id, setId ] = useState('')
  useEffect(() => { setId(newId()) }, [])
  return <>
    <label className='sr-only' htmlFor={id}>{label}</label>
    <textarea {...rest} ref={ref} id={id}>
      {children}      
    </textarea>
  </>
})

export const Button = forwardRef((props, ref) => {
  const {children, label, ...rest} = props
  return <>
    <button {...rest} ref={ref}>
      <span className='sr-only'>{label}</span>
      {children}
    </button>
  </>
})

export const ImgButton = forwardRef((props, ref) => {
  const {image, children, ...rest} = props
  return <Span role='button' style={{display:'inline-block'}} {...rest}>
    <span aria-hidden={true} className = {image}/>
    {children}
  </Span>          
})

export const Title = props =>{
  const {children, id, required, none, ...rest} = props
  return <span className='title' {...rest}>
    <span id={id} style={{fontSize: '16px'}}>{children}</span>
    <abbr title="Required" aria-label='Required'>{none? '' : required ? '*': ''}</abbr>
  </span>
}

export const Link = forwardRef((props, ref) => {
  const {children, label, className, href, style, disabled, onClick, ...rest} = props
  const _ref = ref || useRef({})
  const _onKeyDown= event => {
    if(event.keyCode===Key.ENTER){
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      _ref.current.click()
    }
  }
  const _onClick = event => {
    if(!href || href==='#') {
      event.preventDefault()
      onClick(event)
    }
  }
  return <>
  <div className={`link ${className}`} style={style}>
    {disabled ? 
    <a {...rest} ref={_ref} href={!!href ? href: '#'} className="disabled">
      {children}
      <span className='sr-only'>{label}</span>
    </a>
    :
    <a {...rest} ref={_ref} href={!!href ? href: '#'} className='earlink' onClick={_onClick} onKeyDown={_onKeyDown}>
      {children}
      <span className='sr-only'>{label}</span>
    </a>
    }
    </div>
  </>
})

export const HTMLDiv = props =>{
  const {content, placeHolder, xss=true, ...rest} = props
  const getHtml = value => !!!xss ? 
  {__html: (value||'').replace(/(\r\n|\n|\r)/g, '<br>')}
  :
  {__html: (value||'')
            .split('<').join('&lt;')
            .split('>').join('&gt;')
            .split('"').join('&quot;')
            .split("'").join('&apos;')
            .replace(/(\n|\r|\r\n)/g, '<br>')}
            
  return <>
  {
    !!content ? 
    <div dangerouslySetInnerHTML={getHtml(content)} {...rest}/>
    :
    <div className='place-holder'>{placeHolder||''}</div>
  }
  
  </>
}


