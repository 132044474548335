import React, { useState, useRef, useEffect, useCallback, useImperativeHandle, forwardRef } from 'react'
import DropDown from './dropdown-box'
import DatePicker from './date-picker';
import { Div } from './elements'
import newId from '../../utils/new-id'
import { toLocalDateTime, isValidDate, isValidDateStr, toDate } from '../../utils/date-util'

export default forwardRef((props, ref) => {
  const { style, children, onApply, format }= props
  const [ from, setFrom] = useState()
  const [ to, setTo] = useState()
  const [ message, setMessage] = useState('')
  const [ fromId, setFromId ] = useState('')
  const [ toId, setToId ] = useState('')
  const modalRef = useRef({})
  const dpFromRef = useRef({})
  const dpToRef = useRef({})

  useImperativeHandle(ref, () => ({
    init(e){ onClear() },
    setValues(start, end) {
      setFrom(start)
      setTo(end)
    }
  }))

  useEffect(() => {
    setFromId(newId())
    setToId(newId())
  }, [])

  const onClose = e => modalRef.current.hideDropMenu(e)

  const onClickApply = () =>{
    if(setApply()) {
      onClose()
    }
  }

  const setApply = () =>{
    const __from = dpFromRef.current.getValue()
    const __to = dpToRef.current.getValue()

    if (__from && !isValidDateStr(__from) || __to && !isValidDateStr(__to)) {
      return false
    }

    setFrom(__from)
    setTo(__to)

    const _from = new Date(__from)
    const _to = new Date(__to)
    
    const validFrom = isValidDate(_from)
    const validTo = isValidDate(_to) 

    if(!(validFrom && validTo && _to.getTime() < _from.getTime())) {
      onApply({ 
        from: validFrom ? toDate(toLocalDateTime( _from,'YYYY-MM-DD')): null, 
        to: validTo ? toDate(toLocalDateTime(_to,'YYYY-MM-DD')): null 
      })
      setMessage('')
      return true
    }else{
      setMessage('Not a valid date range.')
    }
  }

  const onClear = () =>{
    dpFromRef.current.setValue('')
    dpToRef.current.setValue('')
    setFrom()
    setTo()
  }

  const onDayChange = () =>{
    setMessage('')
  }

  console.log('dateRange.render')
  return (
    <div className='date-criteria'>
      <DropDown ref={modalRef} 
        style={style} 
        menuStyle={{width: '310px'}} 
        onDropMenuHide={setApply}
        display={children}>
        <div className='row'>
          <div className='col-sm-6'>
            <label htmlFor={fromId} style={{'fontSize': '13px', 'marginBottom': '2px'}}>From</label>
            <DatePicker id={fromId} value={from} ref={dpFromRef} format={format} onDayChange={onDayChange}/>
          </div>
          <div className='col-sm-6'>
            <label htmlFor={toId} style={{'fontSize': '13px', 'marginBottom': '2px'}}>To</label>
            <DatePicker id={toId} value={to} ref={dpToRef} format={format} onDayChange={onDayChange}
              dayPickerProps={{ disabledDays: { before: toLocalDateTime(new Date(from)) }}}/>
          </div>
        </div>
        {message && 
          <div className='row' style={{fontSize: '12px', color: 'red'}}><div className='col-sm'>{message}</div></div>
        }
        <div className='row' style={{marginTop: '10px'}}>
          <div className='col-sm'>
          <Div style={{width: '45px', float: 'left'}} className='clickable-div' onClick={onClickApply}>Apply</Div>
          <Div style={{width: '45px', float: 'left', marginLeft: '10px'}} className='clickable-div' onClick={onClear}>Clear</Div>
          </div>
        </div>
      </DropDown>
    </div>
  )
})