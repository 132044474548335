import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import Modal from './modal'
import { newId } from '../../utils'
import { Span } from './elements'
import { DESTROY_ALL } from '../../services/action-types'
import './confirm.scss'

export default props => new Promise((resolve, reject) => {

  let confirmDialogDiv = document.createElement('div')
  document.body.appendChild(confirmDialogDiv)
  
  let destroy = () => removeDialog()
  document.addEventListener(DESTROY_ALL, destroy)

  const removeDialog = () => {
    ReactDOM.unmountComponentAtNode(confirmDialogDiv);
    document.body.removeChild(confirmDialogDiv);
    document.removeEventListener(DESTROY_ALL, destroy)
  }

  const onHide = e => {
    removeDialog()
    reject(e)
  }
  
  const onOk = e => {
    removeDialog()
    resolve(e)
  }
  
  ReactDOM.render(
    <ConfirmDialog onHide={onHide} onOk={onOk} {...props}/>,
    confirmDialogDiv
  )
})

const ConfirmDialog = props => {
  const { onHide, onOk, title, content, okText='OK', showCancel=true, noFrame=false } = props
  const [contentId, setContentId] = useState()
  const modalRef = useRef({})
  useEffect(() => { modalRef.current.show(); setContentId(newId()) }, [])
  const onClickHide = () => {
    modalRef.current.hide()
  }
  const onClickOk = () => {
    onOk()
    // modalRef.current.hide()
  }

  const styles={
    width: 'inherit',
    left: 'calc(50% - 250px)',
    maxWidth: '500px',
    height: 'inherit'
  }
  
  return <div className='confirm'>
    <Modal ref={modalRef} role="alertdialog" describedBy={contentId} onHide={onHide} style={styles} 
      header={title} 
      footer={<div className='row'><div className='col-sm-12'>
        {okText && <button className='btn btn-primary' onClick={onClickOk}>{okText}</button>}
        {showCancel && <Span className='clickable-div' style={{marginLeft: '15px'}} onClick={onClickHide}>Cancel</Span>}
      </div></div>}>
      <div id={contentId} className={!noFrame ? 'sub-text': ''}>{content}</div>
    </Modal>
  </div>

} 