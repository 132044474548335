let activeElement = {}
let focusSelector = ''
export const jumpFocus = _focus => {
    activeElement = document.activeElement
    if(focusSelector && document.querySelector(focusSelector)){
        document.querySelector(focusSelector).removeEventListener('keydown', restoreLastFocus)
    }

    focusSelector = _focus
    if(focusSelector && document.querySelector(focusSelector)){
        document.querySelector(focusSelector).focus()    
        document.querySelector(focusSelector).addEventListener('keydown', restoreLastFocus)
    }
    function restoreLastFocus(event) {
        if(event.shiftKey && event.keyCode == 9 && document.activeElement.className.indexOf(focusSelector.replace('.', ''))>-1){
            event.preventDefault() 
            activeElement.focus()
        }
    }
}

export const jumpFocus2 = _focus => {
    if(_focus && document.querySelector(_focus)){
        document.querySelector(_focus).focus()
    }
}
export const registerJumpFocus = _focus => {
    window.addEventListener('focus', ()=>jumpFocus2(_focus));
}
export const unregisterJumpFocus = _focus => {
    window.removeEventListener('focus', ()=>jumpFocus2(_focus));
}