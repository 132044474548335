import React, { useState, useEffect, forwardRef } from 'react'
import './list.scss'
import ListItem from './list-item'
import { jumpFocus, jumpFocus2 } from '../../../utils/nav-util'

export default forwardRef((props, ref) => {
  const { listHeight, style, children, itemStyle, rows, onSelectRow, itemClass='simple', nextFocus, escFocus } = props
  const [ listSnap, setListSnap ] = useState([])
  const [ activeRow, setActiveRow ] = useState(-1)  
  const styles = {background: '#fff', ...style}

  useEffect(() => {
    const _listSnap = []
    rows.map(e=>_listSnap.push({...e, selected: false}))
    setListSnap(_listSnap)
  }, [rows])
  
  const onSelect = (index, item, event) =>{
    onSelectRow(item, index)
    const _listSnap = [...listSnap]
    _listSnap.map(e => e.selected = false)
    _listSnap[index] && (_listSnap[index].selected = true)
    setListSnap(_listSnap)
  }
  
  const onNavigate =(row, event) => {
    // selectRow(nextRow, listSnap[nextRow])
    if (event.keyCode === 40) {       // Down
      row < listSnap.length -1 && setActiveRow(row + 1 )
    } else if (event.keyCode === 38 ) { // Up
      row > 0 && setActiveRow(row - 1 )
    } else if (event.keyCode === 9 && !event.shiftKey && nextFocus) { // Tab
      event.preventDefault()
      jumpFocus(nextFocus)
    } else if (event.keyCode === 9 && event.shiftKey && escFocus) { // Shift + Tab
      event.preventDefault()
      jumpFocus2(escFocus)
    }
  }

  return (
    <div style={styles}>
      <div ref={ref} className='list' style={{height: listHeight}}>
        <ul>{listSnap.map((item, i) => 
          <li key={i} className={`${itemClass} ${listSnap[i] && listSnap[i].selected ? 'itemSelected' : 'item'}`} 
            style={{...itemStyle}}>
            <ListItem 
              onSelect={ event => onSelect(i, item, event)} 
              onNavigate={ event => onNavigate(i, event)}
              actived={ activeRow === i }
              >
              {()=>children(item, i)}
            </ListItem>
          </li>)
        }</ul>
      </div>
    </div>
  )
})