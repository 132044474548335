import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle} from 'react'
import { Key, newId } from '../../../utils'
import ResizeDetector from '../resize-detector'
import Scrollbar from '../scroll-bar'

import './dropdown.scss'

export default forwardRef((props, ref) =>  {
  const { 
    className, style, menuClassName, menuStyle, id, menuRole="menu", required=false, focusable=false, labelledBy, menuLabelledBy, menuLabel='dropdown menu',
    display, children, scrollable=false, pullRight = false, pullUp = false, autoHide = false, fixed=false, nextFocus,
    menuHeader,
    onDropMenu=()=>{},
    onKeyDown=()=>{},
    onBlur=()=>{},
    onClick=()=>{},
    focusOnFirst=()=>{},
    clear=()=>{}
  }=props
  
  const [toggleOn, setToggleOn] = useState(false)
  const [menuHeight, setMenuHeight] = useState(0)
  const [activeElement, setActiveElement] = useState({})
  const [menuId, setMenuId] = useState('')
  const wrapperRef = useRef({})
  const menuRef = useRef({})

  useEffect(()=> {
    setMenuId(!!id? id: newId())
  }, [id])

  const handleClickOutside = event => blurDropMenu(event.target)

  const handleKeypress = event => {
    if(event.keyCode === Key.ESCAPE){
      onHideDropMenu()
    }else if(event.keyCode === Key.TAB){
      setTimeout(()=>blurDropMenu(document.activeElement), 100)
    }
  }

  const blurDropMenu = target => {
    if (!wrapperRef || !wrapperRef.current || menuRef.current.contains(target)) return
    onHideDropMenu(false)
  }

  const api = () => ({
    showDropMenu: e => onShowDropMenu(e),
    hideDropMenu: (r, m) => onHideDropMenu(r, m),
    contains: e => {return (wrapperRef.current.contains(e))},
    isToggleOn: () => toggleOn,
    focusOnFirst: () => focusOnFirst(menuRef),
    clear: () => clear(menuRef)
  })
  useImperativeHandle(ref, api)
  
  const onShowDropMenu = () =>{
    setActiveElement(document.activeElement)
    setToggleOn(true)
    onDropMenu(true)
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keyup', handleKeypress)
  }

  const onHideDropMenu = (restoreFocus = true, moveNext = false) =>{
    setToggleOn(false)
    onDropMenu(false)
    if (restoreFocus) {
      setTimeout(() => {
        if (moveNext && nextFocus) {
          let next = document.querySelector(nextFocus) 
          next && next.focus()
        } else {
          activeElement.focus && activeElement.focus()
        }
      })
    } 
    document.removeEventListener('mousedown', handleClickOutside)
    document.removeEventListener('keyup', handleKeypress)
  }

  const onMouseLeave = event => {
    if (autoHide) {
      onHideDropMenu(false)
      event.preventDefault()
    }
  }

  const onResize = (w,h) => setMenuHeight(h + 27)

  return (
    <div className={`custom-dropdown2 ${className} ${pullRight && 'pull-right'} ${fixed && 'fixed'}`} 
    ref={wrapperRef} style={{...style}} onClick={onClick} onBlur={onBlur} onKeyDown={onKeyDown} onMouseLeave={onMouseLeave}>
      <span className={`dropdown ${scrollable ? 'scrollable' : ''}`} >
        {!focusable ? 
          <span id={`${menuId}_btn`} className='dropdown-display' tabIndex='0'
            role='button' aria-haspopup={menuRole} aria-controls={menuId} aria-expanded={toggleOn} 
            aria-labelledby={!!labelledBy ? labelledBy : !!menuLabelledBy ? menuLabelledBy: `${menuId}_btn`}>
            { display }
          </span>
          :  display
        }
        <div ref={menuRef} id={menuId} role={menuRole} {...(menuLabelledBy ? {'aria-labelledby': menuLabelledBy}:{'aria-label':menuLabel})}
          className={`dropdown-menu ${menuClassName} ${toggleOn && 'show'}  ${pullUp && 'pull-up'}`} 
          style={scrollable ? {...menuStyle, height: `${menuHeight}px`}: menuStyle}>
          
          { !scrollable ? children :
            <Scrollbar><div style={{display: 'flex', flexDirection: 'column'}}>
              { menuHeader ? <div className='row'>{menuHeader}</div> : <></> }
              { children }
              <ResizeDetector onResize={onResize}/>
              </div>
            </Scrollbar>
          }
        </div>
      </span>
    </div>
  )
})