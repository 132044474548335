import React, { useRef, forwardRef, useImperativeHandle} from 'react'
import DropDownBase from './dropdown-base'
import { Key } from '../../../utils'
import './dropdown.scss'
export default forwardRef((props, ref) =>  {
  const {children, id, menuRole, spaceDown=true, hasGroup=false, autoFocus=true, closeOnSelect=true, onKeyDown=()=>{}, onDropMenu=()=>{}, onBlur=()=>{},...rest}=props
  const wrapperRef = useRef({})

  useImperativeHandle(ref, () => ({
		showDropMenu(e){ wrapperRef.current.showDropMenu(e) },
    hideDropMenu(e){ wrapperRef.current.hideDropMenu(e) },
    contains: e => {return (wrapperRef.current.contains(e))},
    isToggleOn: () => wrapperRef.current.isToggleOn(),
    focusOnFirst: () => wrapperRef.current.focusOnFirst(),
    clear: () => wrapperRef.current.clear(),
  }))

  const focusOnFirst = menuRef => {
    setTimeout(()=> {
      let items = menuRef.current.querySelectorAll('.dropdown-item[tabindex="-1"]') || []
      if (items.length > 0) {
        items[0].setAttribute('tabindex', '0')
        items[0].focus()
      }
    })
  }
  
  const _onKeyDown = e => {
    if (e.keyCode === Key.ENTER || e.keyCode === Key.SPACE){
      if(spaceDown){
        e.preventDefault()
        e.stopPropagation()
      }
      onClick(e)
    } else if (e.keyCode === Key.DOWN_ARROW) {   
      e.preventDefault()
      e.stopPropagation()

      if (wrapperRef.current.isToggleOn) {
        let isDropItem = e.target.className.indexOf('dropdown-item') >-1
        let next = isDropItem && findNextSibling(e.target)
        if (next) {
          e.target.setAttribute('tabindex', '-1')
          next.setAttribute('tabindex', '0')
          next.focus() 
        } else if (!isDropItem) {
          let children = e.target.closest('.dropdown').querySelectorAll('.dropdown-item[tabIndex="-1"]')
          if (children && children.length > 0) {
            children[0].setAttribute('tabindex', '0')
            return children[0].focus()
          }
        }
      }
    } else if (e.keyCode === Key.UP_ARROW) { 
      e.preventDefault()
      e.stopPropagation()
      let prev = findPrevSibling(e.target)
      if (prev) {
        e.target.setAttribute('tabindex', '-1')
        prev.setAttribute('tabindex', '0')
        prev.focus() 
      }
    }
    onKeyDown(e)
  }

  const findNextSibling = e => {
    while (e.nextElementSibling) {
        if (e.nextElementSibling.className.indexOf('dropdown-item')>-1 && e.nextElementSibling.getAttribute("tabIndex")==='-1') {
            return e.nextElementSibling
        } 
        e = e.nextElementSibling
    }
    
    if (hasGroup) {
      return findNextGroupSibling(e)
    }
    return null
  }

  const findNextGroupSibling = e => {
    let group = e.parentElement

    while (group.nextElementSibling) {
      let children = group.nextElementSibling.querySelectorAll('.dropdown-item[tabIndex="-1"]')
      if (children && children.length > 0) {
        return children[0]
      }
      group = group.nextElementSibling
    }

    return null
  }

  const findPrevSibling = e => {
    while (e.previousElementSibling) {
      if (e.previousElementSibling.className.indexOf('dropdown-item')>-1 && e.previousElementSibling.getAttribute("tabIndex")==='-1') {
          return e.previousElementSibling
      } 
      e = e.previousElementSibling
    }
    if (hasGroup) {
      return findPrevGroupSibling(e)
    }
    return null
  }

  const findPrevGroupSibling = e => {
    let group = e.parentElement

    while (group.previousElementSibling) {
      let children = group.previousElementSibling.querySelectorAll('.dropdown-item[tabIndex="-1"]')
      if (children && children.length > 0) {
        return children[children.length - 1]
      }
      group = group.previousElementSibling
    }

    return null
  }

  const clear = menuRef => {
    let items = menuRef.current.querySelectorAll('.dropdown-item[tabIndex="0"]') || []
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        items[i].setAttribute('tabindex', '-1')
      }
    }
  }

  const onClick = e => {
    if(!wrapperRef.current.isToggleOn()){
      wrapperRef.current.showDropMenu(e)
      if (autoFocus && e.type!=='click') {
        setTimeout(()=>wrapperRef.current.focusOnFirst())
      }
    }else if (closeOnSelect && (e.target.tabIndex>=-1 || e.target.parentNode.tabIndex>=-1 || e.target.parentNode.parentNode.tabIndex>=-1)){//TODO: find ancestor by common way
      wrapperRef.current.hideDropMenu(true, true)
    }
  }

  const _onDropMenu = e => {
    !e && wrapperRef.current.clear()
    onDropMenu(e)
  }

  return (
    <DropDownBase ref={wrapperRef} id={id} menuRole={menuRole} {...rest} onKeyDown={_onKeyDown} 
      onClick={onClick} onBlur={onBlur} onDropMenu={_onDropMenu} focusOnFirst={focusOnFirst} clear={clear}>
      {children}
    </DropDownBase>
  )
})