import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import Draggable from 'react-draggable'
import { Button } from './elements'
import './modal.scss'
	
export default forwardRef((props, ref) => {
  const { header, footer, style, className, onHide=()=>{}, children, ...rest } = props
  const [ showing, setShowing ] = useState(false)
  const [ activeDrags, setActiveDrags ] = useState(0)
  const firstFocusRef = useRef({}), modalRef = useRef({})
  const lastFocusRef = useRef({})

  const [activeElement, setActiveElement] = useState({})

  const handleKeypress = event => {}
  
  useImperativeHandle(ref, () => ({
    show(){ 
      setTimeout(() => {
        setShowing(true) 
        setActiveElement(document.activeElement)
        setFirstFocus()
        document.addEventListener('keydown', handleKeypress)
      }, 100)// the delay is for restoring focus when it pops from a dropdown menu.
      
    },
    hide(){ onClickHide() }
  }))

  const setFirstFocus = () => setTimeout(() => {
    let focusableEle = modalRef.current && modalRef.current.querySelectorAll("input:not([type='checkbox']):not([type='radio']), label[tabindex='0'], div[tabindex='0'], span[tabindex='0'], .fa[tabindex='0'], textarea") || [];
      
    if (focusableEle.length === 0 ) {
      focusableEle = modalRef.current && modalRef.current.querySelectorAll('button:not([disabled]), button.flat-button') || [];
    }

    if (focusableEle.length === 0) {
      focusableEle = modalRef.current && modalRef.current.querySelectorAll('button.e-modal-close') || [];
    }

    if (focusableEle.length > 0) { 
      focusableEle[0].focus(); 
    }
  }, 100)

  const onKeyDownFirst = event=>{
    if (event.which === 9 && event.shiftKey) {
      event.preventDefault()
      lastFocusRef.current.dispatchEvent(new KeyboardEvent('keydown',{'key':'Shift'}))
    }
  }

  const onKeyDownLast = () =>{}

  const onFocusLast = event=>{
    event.preventDefault()    
    setFirstFocus()
  }

  const onClickHide = () => {
    setShowing(false)
    activeElement.focus && activeElement.focus()
    document.removeEventListener('keydown', handleKeypress)
    onHide()
  }

  const handleDragStart = () =>setActiveDrags(activeDrags + 1)

  const handleDragStop = () =>setActiveDrags(activeDrags - 1)

  const dragHandlers = { onStart: handleDragStart, onStop: handleDragStop }

  console.log('modal.render')
  return <span className={showing ? 'e-modal' : 'e-modal-hide'}>
    <div className='e-modal-background'></div>
    <Draggable handle='strong' bounds='parent' {...dragHandlers} >
      <div ref={modalRef} className={`e-modal-dialog ${className}`} style={{...style}} {...rest}>
        <div className='e-modal-header' tabIndex={0} role='heading' aria-level='3'>
          <strong>{header||'\u00A0'}</strong>
          <Button className='e-modal-close' ref={firstFocusRef} onKeyDown={onKeyDownFirst} onClick={onClickHide} label='Close'>
            <span className='fa fa-close'></span>
          </Button>
        </div>
        {children}
        {footer && <div className='e-modal-footer'>{footer}</div>}
        <Button className='last-focus' ref={lastFocusRef} onKeyDown={onKeyDownLast} onFocus={onFocusLast} label='Go top'></Button>
      </div>
    </Draggable>
  </span>
})