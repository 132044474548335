import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Key } from '../../../utils'
import TreeNode from './treenode'
import './tree.scss'

export default forwardRef((props, ref) => {
    const { roots, defaultCollapsed, defaultSelected, parentNodeStyle, leafNodeStyle, selectedNodeStyle, headerStyle, emptyCollapsible=true,
        getRootLabel=()=>{}, getNodeLabel=()=>{}, getId=()=>{}, expandId=-1, nodeSelected=()=>{}} = props
    const [selected, setSelected] = useState()

    useEffect(()=> {
        setSelected(defaultSelected)
    }, [defaultSelected])

    useImperativeHandle(ref, () => ({
		clear() { 
            setSelected() 
        }
    }))

    const selecteNode = node => {
        setSelected(node)
        nodeSelected(node)
    }

    const onKeyDown = (event, item) => {
        if (event.keyCode === Key.UP_ARROW || event.keyCode === Key.DOWN_ARROW) {  
            event.preventDefault()
            event.stopPropagation()
            let treeItems = event.target.closest(".tree").querySelectorAll(".tree-item")
            if (treeItems.length == 0) return
            let lastIndex = treeItems.length - 1;

            let currentIndex = 0;
            for (let i = 0; i < treeItems.length; i++) {
                if (treeItems[i] == event.target) {
                    currentIndex = i;
                    break;
                }
            }
    
            let nextIndex = event.keyCode === Key.UP_ARROW ? currentIndex - 1 : currentIndex + 1;
            if (nextIndex >= 0 && nextIndex <= lastIndex) {
                treeItems[nextIndex].focus();
            }
        } else if (event.keyCode === Key.ENTER) {   
            selecteNode(event, item)
        } 
      
    }
   
    const constructTreeNode = (item) => {
        if (item.children && (emptyCollapsible || item.children.length > 0)) {
            return (
                <div key={getId(item)}>
                    <TreeNode 
                        nodeLabel={item.isRoot ? getRootLabel(item): getNodeLabel(item)} 
                        item={item} 
                        getId={getId}
                        onKeyDown={onKeyDown} 
                        nodeSelected={()=>selecteNode(item)} 
                        expandId={expandId}
                        initCollapsed={defaultCollapsed} 
                        headerStyle={headerStyle}>
                        <ul className="tree-item-parent" style={parentNodeStyle}>
                            { item.children.map(constructTreeNode) }
                        </ul>
                    </TreeNode>
                </div>
            )
        } else {
            return (
                <li style={{marginLeft: '0px'}} key={getId(item)}>
                    <span 
                        className={`tree-item ${selected == item && 'selected'}`} 
                        style={{...leafNodeStyle, ...(selected==item ? selectedNodeStyle :{})}}
                        tabIndex="0" 
                        onKeyDown={event=>onKeyDown(event, item)} 
                        onClick={()=>selecteNode(item)}>
                        {getNodeLabel(item)}
                    </span>
                </li>
            )
        }
    }

    return (
        <div className="tree" >
            <ul className="tree-item-parent" style={parentNodeStyle}>
                {roots && roots.map(constructTreeNode)}
            </ul>
        </div>
    )
})