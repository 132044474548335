import React , { Suspense, lazy, useState, useEffect }from 'react'
import { runWithAdal } from 'react-adal'
import { adalSetup } from './services/adal-config'
import fetchWithTimeout from './services/fetch-with-timeout'
import { isBpsLanding, getBpsTenant, setBpsTenant } from './utils'
import Landing from './components/auth/landing'

import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/app.scss'

// import App from './app'
const App = lazy(() => import('./app'))

const url = 'public/adal-config'
const options = {
  credentials: 'same-origin' ,
  method: 'GET',
  headers: { 
    'Content-Type': 'application/json; charset=utf-8', 
    'Access-Control-Allow-Origin': '*',
    observe: 'response'
  }
}

export default () => {
  const [loading, setLoading] = useState({status: 1, data: {}})   // -1 bps landing; 1 loading; 0 done
  const [message, setMessage] = useState('Please wait while the application loads ...')
  
  useEffect(() => {
    fetchWithTimeout(url, options)
    .then(checkStatus)
    .then(data => {
      if(isBpsLanding(data)){
        setLoading({...loading, status: -1, data})  
      }else{
        lanuchAdal(getBpsTenant(), data)
      }
    })
    .catch(handleError)
  }, [])

  const onSelectTenant = (tid, bpsTenant) => {
    setBpsTenant(bpsTenant)
    lanuchAdal(tid, loading.data)
  }

  const lanuchAdal = (tenantId, data) => {
    let config = {...data, tenant: tenantId || data.tenant, postLogoutRedirectUri:`${window.location.protocol}//${window.location.host}`}
    console.log('Launch ADAL', config)
    runWithAdal(adalSetup(config), () => setLoading({...loading, status: 0}), false)  
    setBpsTenant(config.tenant)
  }

  const showMessage = msg => <div><div style={{textAlign: 'center', fontSize: '30px', marginTop: '20%', whiteSpace: 'pre'}}>{msg}</div></div>

  const checkStatus = response => new Promise((resolve, reject) => {
    if(response.status===200|| response.status===201){
      response.json().then(resolve).catch(reject)
    }else{
      reject(response)
    }
  })

  const handleError = error=>{
    console.log(error)
    let errorText = 'The service you are attempting to access is currently unavailable. Please try again at a later time.';
    if(error.statusText){
      errorText = `${errorText}\nStatus:${error.statusText}`
    }
    if(error.url){
      errorText = `${errorText}\nURL:${error.url}`
    }
    setMessage(errorText)
  }

  return (<Suspense fallback={showMessage('')}>{
    loading.status === -1 ?
    <Landing onSelect={onSelectTenant} tenants={loading.data.bpsTenants}/>
    : 
    loading.status === 0 ? 
    <App/>
    :
    showMessage(message.content)
  }</Suspense>)
}
