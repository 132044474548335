import React, { useState, useEffect, useRef,forwardRef, useImperativeHandle } from 'react'
import DayPicker from 'react-day-picker'
import { Input, Span } from '../common/elements'
import { toDate, isValidDate, isValidDateStr, toLocalDateTime, toMonth } from '../../utils/date-util'
import 'react-day-picker/lib/style.css'
import './date-picker.scss'
export default forwardRef((props, ref) =>  {
  const { id, value, onDayChange=()=>{}, format, dayPickerProps } = props
  const [ show, setShow ] = useState(false)
  const [ selectedDay, setSelectedDay ] = useState(null)
  const [ month, setMonth ] = useState(toMonth(new Date()))
  const [ valid, setValid ] = useState(true)
  const wrapperRef = useRef({})

  useEffect(() => { wrapperRef.current.value = value || '' }, [value])
  useImperativeHandle(ref, () => ({
    getValue(){
      setShow(false)
      return wrapperRef.current.value
    },
    setValue(value){
      wrapperRef.current.value = value
      verifyDate(value)
    }
  }))

  const onDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }
    setShow(false)
    const _date = toDate(day, format)
    setSelectedDay(_date)
    onDayChange(_date)
    wrapperRef.current.value = _date
    wrapperRef.current.focus()
  }
  
  const onClickShow = () => {
    let _date = new Date(value || '')
    if(!isValidDate(_date)){
      _date = new Date()
    }
    _date = toLocalDateTime(_date)
    setSelectedDay(_date)
    setMonth(toMonth(_date))
    setShow(!show)
  }

  const onBlur = event => verifyDate(event.target.value)

  const verifyDate = value => {
    const _date = new Date(value)
    let _valid = true

    if(isValidDateStr(value) && isValidDate(_date)){
      onDayChange(toDate(toLocalDateTime(_date),'YYYY-MM-DD'))      
    }else{
      onDayChange(value)      
      if(value){
        _valid = false
        setTimeout(() => setShow(false), 200)
      }
    }
    setValid(_valid)
  }

  const onFocus = event => {
    const _date = new Date(event.target.value)
    let _valid = true

    if((!isValidDate(_date) || !isValidDateStr(event.target.value)) && event.target.value){
      _valid = false
    }
    setValid(_valid)
  }
  
  const onInput = event => {
    if(!event.target.value){
      setValid(true)
    }else{
      setShow(false)
    }
  }

  const onDayKeyDown = (day, modifiers, event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation();
  }
  
  console.log('datePicker.render')
  return <div className='date-picker'>
    <input className={`date-picker-input ${!valid ? 'invalid': ''}`} 
      id={id} label='Date Picker' ref={ wrapperRef } placeholder={ format } 
    onInput={onInput}
    onClick={onClickShow}
    onBlur={onBlur}
    onFocus={onFocus}/>
    <Span className='fa fa-calendar' aria-label='Date Picker Calendar' onClick={ onClickShow }/>
    {!valid && <span style={{fontSize: '12px', color: 'red'}}>Invalid date</span>}
    { show && <DayPicker onDayKeyDown={onDayKeyDown} onDayClick={onDayClick} month={month} {...dayPickerProps} selectedDays={selectedDay}/> }
  </div>
})