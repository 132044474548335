import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import { isIE } from './browser-util'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(AdvancedFormat)

export const toDate = (dt, format) => {
    let ret = removeTime(dt);
    return formatDate(ret, format || 'MMM D, YYYY');
}

export const toDate2 = (dt, format) => dt && toDate(dt.substring(0,10), format || 'MMM DD, YYYY');

export const toShortDate = dt =>{
    return (formatDate(dt, 'MMM D, YYYY')||'').replace(`, ${dayjs().format('YYYY')}`, '')
}

export const toDateTime = dt =>formatDate(dt, 'MMM D, YYYY h:mm A')

export const toDateTime2 = (dateStr, format) => dateStr && dayjs1(new Date(dateStr), format)

export const toTime = dt =>formatDate(dt, 'h:mm A')

export const toDateTimeWithSeconds = dt => formatDate(dt, 'MMM D, YYYY h:mm:ss A')

export const formatDate = (dt, format) =>dt && dayjs1(dt, format)

export const isValidDate = dt => dt instanceof Date && !isNaN(dt.getTime())

export const isValidDateStr = ds => ds && ds.match(/^\d{4}-\d{2}-\d{2}$/g)

export const toLocalDateTime = dt => new Date(dt.getTime() + dt.getTimezoneOffset() * 60000)

export const toMonth = dt => new Date(dt.getFullYear(), dt.getMonth())

const dayjs1 = (dt, format) =>{
    let ret = parseDate(dt)
    try{
        let dt1 = dayjs(removeNanoSeconds(dt));
        ret = dt1.format(format)
    }catch(e){
        console.log(e)
    }
    
    let result = (ret === 'Invalid Date') ? dt : ret;
    return removeNanoSeconds(result);
}

const parseDate = dt =>{
    dt = removeNanoSeconds(dt);
    if(!isIE() || dt instanceof Date) return dt
    const date=dt.split('-')
    if(date.length > 1 && date[1].length > 2){
        return `${date[1]} ${date[0]}, ${date[2].length===2 && '20'}${date[2]}`
    }else{
        return dt
    }
}

const removeTime = dt => {
    if (dt && dt instanceof Date) {
        dt.setHours(null);
        dt.setMinutes(null);
        dt.setSeconds(null);
        dt.setMilliseconds(null);
    }else if(dt){
        dt = dt.replace('00:00:00', '').trim();
    }

    return dt;
}

const removeNanoSeconds = dt => {
    if (dt && dt instanceof Date) {
        dt.setMilliseconds(null);
    }else if(dt){
        dt = dt.replace('.000000', '').trim();
    }

    return dt;
}

export const buildDate = dt =>{
    if(!isIE() || dt instanceof Date) return dt
    const date=dt.split(/[ ,]+/)
    if(date.length === 2){
        const dt1 = date[0] + " 1," + date[1]
        return new Date(dt1)
    }else{
        return new Date(dt)
    }
}

export const sortDatesDescending = dates => dates.sort((a, b) => buildDate(b.period) - buildDate(a.period))