import React from 'react'
// import ReactPaginate from 'react-paginate'
import './paginate.scss'

class paginate extends React.Component{
  render() {
    console.log('paginate.render')
    // const {pageCount, marginPagesDisplayed, pageRangeDisplayed, onPageChange}=this.props
    return (
      <div className='paginate'>
        {/* <ReactPaginate
          previousLabel='<'
          nextLabel='>'
          pageCount={pageCount}
          marginPagesDisplayed={marginPagesDisplayed}
          pageRangeDisplayed={pageRangeDisplayed}
          onPageChange={onPageChange}
          breakClassName="page-item"
          breakLabel={<div className="page-link">...</div>}
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName={"active"}
        /> */}
      </div>
    )
  }
}
paginate.defaultProps = {
  pageCount: 20,
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 3,
  // initialPage,
  // forcePage,
  onPageChange: ()=>{}
}
export default paginate