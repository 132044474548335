//--------Test Actions Begin-----------------------------------------
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const ADAL_LOGOUT = 'ADAL_LOGOUT'

export const CONTACT_LIST = 'CONTACT_LIST'

export const CONTACT_REFRESH = 'CONTACT_REFRESH'
export const CONTACT_CLEAR = 'CONTACT_CLEAR'

export const DECISION_LOAD = 'DECISION_LOAD'
export const DECISION_CLEAR = 'DECISION_CLEAR'

export const DOC_LOAD = 'DOC_LOAD'
export const DOC_CLEAR = 'DOC_CLEAR'
//--------Test Actions End-------------------------------------------

//--------Http Request Begin-----------------------------------------
export const HTTP_REQUEST = 'HTTP_REQUEST'
//--------Http Request End-------------------------------------------

//--------Auth Begin-------------------------------------------------
export const AUTH_NONE = 'AUTH_NONE'
export const AUTH_STARTING = 'AUTH_STARTING'
export const AUTH_NO_PERMISSION = 'AUTH_NO_PERMISSION'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const AUTH_TIMEOUT = 'AUTH_TIMEOUT'
export const AUTH_NO_ACCESS_BPS = 'AUTH_NO_ACCESS_BPS'
//--------Auth End--------------------------------------------------

//--------Nav Begin-------------------------------------------------
export const NAV_APP = 'NAV_APP'
export const NAV_MINISTRY = 'NAV_MINISTRY'
export const NAV_MENU = 'NAV_MENU'
export const PAMS_MENU = 'PAMS_MENU'
export const TMS_MENU = 'TMS_MENU'
export const EVISTA_MENU = 'EVISTA_MENU'
//--------Nav End--------------------------------------------------

//--------Value List Begin------------------------------------------
export const VALUE_LIST_INIT = 'VALUE_LIST_INIT'
export const VALUE_LIST_REFRESH = 'VALUE_LIST_REFRESH'
//--------Value List End--------------------------------------------

//--------Admin Begin-----------------------------------------------
export const ADMIN_APP_SELECT = 'ADMIN_APP_SELECT'
export const ADMIN_MINISTRY_LIST_SELECT_SINGLE = 'ADMIN_MINISTRY_LIST_SELECT_SINGLE'
export const ADMIN_USER_LIST = 'ADMIN_USER_LIST'
export const ADMIN_AD_USER = 'ADMIN_AD_USER'
export const ADMIN_TMS_IND_DETAIL = 'ADMIN_TMS_IND_DETAIL'
export const ADMIN_TMS_AD_TENANTS = 'ADMIN_TMS_AD_TENANTS'
export const ADMIN_TMS_IND_WINID = 'ADMIN_TMS_IND_WINID'

export const ADMIN_SYSTEM_VALUE_LIST = 'ADMIN_SYSTEM_VALUE_LIST'
export const ADMIN_BUSINESS_VALUE_LIST = 'ADMIN_BUSINESS_VALUE_LIST'
// export const ADMIN_USER_LIST = 'ADMIN_USER_LIST'
//--------Admin End------------------------------------------------

//--------Contact Begin----------------------------------------------
export const CONTACT_LIST_REFRESH = 'CONTACT_LIST_REFRESH'
export const CONTACT_LIST_CLEAR = 'CONTACT_LIST_CLEAR'
export const CONTACT_LIST_SELECT_COL = 'CONTACT_LIST_SELECT_COL'
export const CONTACT_DETAIL = 'CONTACT_DETAIL'
//--------Contact End------------------------------------------------

//--------Pams Begin----------------------------------------------
export const PAMS_LIST_REFRESH = 'PAMS_LIST_REFRESH'
export const PAMS_LIST_CLEAR = 'PAMS_LIST_CLEAR'
export const PAMS_LIST_SELECT_COL = 'PAMS_LIST_SELECT_COL'
export const PAMS_DETAIL = 'PAMS_DETAIL'

export const PAMS_BOARD_REFRESH = 'PAMS_BOARD_REFRESH'
export const PAMS_BOARD_CLEAR = 'PAMS_BOARD_CLEAR'
//--------Contact End------------------------------------------------

//--------CDS Begin----------------------------------------------
export const CDS_LIST_REFRESH = 'CDS_LIST_REFRESH'
export const CDS_LIST_CLEAR = 'CDS_LIST_CLEAR'
export const CDS_LIST_RESET = 'CDS_LIST_RESET'
export const CDS_DETAIL = 'CDS_DETAIL'
export const CDS_MINISTRIES = 'CDS_MINISTRIES'
//--------CDS End------------------------------------------------

//--------CCME Begin----------------------------------------------
export const CCME_LIST_REFRESH = 'CCME_LIST_REFRESH'
export const CCME_LIST_CLEAR = 'CCME_LIST_CLEAR'
export const CCME_LIST_RESET = 'CCME_LIST_RESET'
export const CCME_DETAIL = 'CCME_DETAIL'
export const CCME_MINISTRIES = 'CCME_MINISTRIES'
//--------CCME End------------------------------------------------

//--------IAPPROVE Begin----------------------------------------------
export const IAPPROVE_LIST_REFRESH = 'IAPPROVE_LIST_REFRESH'
export const IAPPROVE_LIST_CLEAR = 'IAPPROVE_LIST_CLEAR'
export const IAPPROVE_LIST_RESET = 'IAPPROVE_LIST_RESET'
export const IAPPROVE_DETAIL = 'IAPPROVE_DETAIL'
export const IAPPROVE_CONFIG = 'IAPPROVE_CONFIG'
//--------IAPPROVE End------------------------------------------------

//--------CAB Begin----------------------------------------------
export const CAB_LIST_REFRESH = 'CAB_LIST_REFRESH'
export const CAB_LIST_CLEAR = 'CAB_LIST_CLEAR'
export const CAB_LIST_RESET = 'CAB_LIST_RESET'
export const CAB_DETAIL = 'CAB_DETAIL'
export const CAB_DETAIL_POPUP = 'CAB_DETAIL_POPUP'
export const CAB_CONFIG = 'CAB_CONFIG'

export const CAB_FILTERS_REFRESH = 'CAB_FILTERS_REFRESH'
export const CAB_FILTER_SELECTED = 'CAB_FILTER_SELECTED'
//--------CAB End------------------------------------------------

//--------TMS Begin----------------------------------------------
export const TMS_LIST_REFRESH = 'TMS_LIST_REFRESH'
export const TMS_EMP_REFRESH = 'TMS_EMP_REFRESH'
export const TMS_MGR_REFRESH = 'TMS_MGR_REFRESH'
export const TMS_LIST_CLEAR = 'TMS_LIST_CLEAR'
export const TMS_LIST_RESET = 'TMS_LIST_RESET'
export const TMS_DETAIL = 'TMS_DETAIL'
export const TMS_CONFIG = 'TMS_CONFIG'
//--------TMS Begin----------------------------------------------

//--------PAU Begin----------------------------------------------
export const PAU_LIST_REFRESH = 'PAU_LIST_REFRESH'
export const PAU_LIST_CLEAR = 'PAU_LIST_CLEAR'
export const PAU_DETAIL = 'PAU_DETAIL'
export const PAU_CONFIG = 'PAU_CONFIG'
//--------PAU End----------------------------------------------

//------- EVista Begin----------------------------------------------
export const EVISTA_SUBMISSION_LIST_REFRESH = 'EVISTA_SUBMISSION_LIST_REFRESH'
export const EVISTA_SUBMISSION_LIST_CLEAR = 'EVISTA_SUBMISSION_LIST_CLEAR'
export const EVISTA_SUBMISSION_LIST_RESET = 'EVISTA_SUBMISSION_LIST_RESET'
export const EVISTA_PREARCHIVED_LIST_REFRESH = 'EVISTA_PREARCHIVED_LIST_REFRESH'
export const EVISTA_PREARCHIVED_LIST_CLEAR = 'EVISTA_PREARCHIVED_LIST_CLEAR'
export const EVISTA_DETAIL = 'EVISTA_DETAIL'
export const EVISTA_CONFIG = 'EVISTA_CONFIG'
//--------EVista End----------------------------------------------

//--------Submission Begin----------------------------------------------
export const SUBMISSION_LIST_REFRESH = 'SUBMISSION_LIST_REFRESH'
export const SUBMISSION_LIST_SELECT_COL = 'SUBMISSION_LIST_SELECT_COL'
export const SUBMISSION_DETAIL = 'SUBMISSION_DETAIL'
//--------Submission End------------------------------------------------

//--------Global Begin--------------------------------------------
export const SPINNER_SHOW = 'SPINNER_SHOW'
export const SPINNER_HIDE = 'SPINNER_HIDE'
export const SPINNER_HIDE_CLEAR = 'SPINNER_HIDE_CLEAR'

export const MESSAGE_SHOW = 'MESSAGE_SHOW'
export const MESSAGE_HIDE = 'MESSAGE_HIDE'

export const DESTROY_ALL = 'DESTROY_ALL'
//--------Global End----------------------------------------------

//--------Application Type Begin--------------------------------------------
export const APP_TYPE_ADMIN = 'ADMIN'
export const APP_TYPE_PAMS = 'PAMS'
export const APP_TYPE_OCMS = 'OCMS'
export const APP_TYPE_CDS = 'CDS'
export const APP_TYPE_CCME = 'CCME'
export const APP_TYPE_IAPPROVE = 'IAPPROVE'
export const APP_TYPE_CAB = 'CAB'
export const APP_TYPE_TMS = 'TMS'
export const APP_TYPE_PAU = 'PAU'
export const APP_TYPE_EVISTA = 'EVISTA'
//--------Application Type End----------------------------------------------

export const AD_USERS_REFRESH = 'AD_USERS_REFRESH'