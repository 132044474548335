import React from 'react'

export default props => {
  const { ext } = props

  const getImg = () => {
      return ext==='pdf' ? 'fa fa-file-pdf-o' :
        (ext==='img' || ext==='png' || ext==='gif' || ext==='jpg') ? 'fa fa-file-image-o' :
        (ext==='xls' || ext==='xlsx') ? 'fa fa-file-excel-o' :
        (ext==='doc' || ext==='docx') ? 'fa fa-file-word-o' : 
        (ext==='ppt' || ext==='pptx') ? 'fa fa-file-powerpoint-o' :
        ext==='txt' ? 'fa fa-file-text-o' :
        (ext==='html' || ext==='htm') ? 'fa fa-internet-explorer' :
        ext==='eml' ? 'fa fa-envelope-o' :
        ext==='msg' ? 'fa fa-envelope' :
        ext==='csv' ? 'fa fa-file-excel-o' :
        'fa fa-file-o'
  }

  return <div>
     <span className={`${getImg()}`} style={{color:ext==='pdf'? 'red': 'inherit'}} aria-hidden="true"></span>
  </div>
}