import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import './utils/polyfill'
import Init from './init'

const render = () => {
    try {
        ReactDOM.render(<Init/>, document.getElementById('root'))
    } catch (err) {
        console.error(err)
    }
}

render()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
