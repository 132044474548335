import { AuthenticationContext } from 'react-adal';
 
export const adalConfig = {
  tenant: '',
  clientId: '',
  endpoints: {
    api: '',
  },
  postLogoutRedirectUri: '',
  cacheLocation: '',
}

let authContext
export const adalSetup = config =>{
  adalConfig.tenant = config.tenant
  adalConfig.clientId = config.clientId
  adalConfig.endpoints.api = config.clientId
  adalConfig.postLogoutRedirectUri = config.postLogoutRedirectUri
  adalConfig.cacheLocation = config.cacheLocation
  adalConfig.tokenRenewalTimeout = config.tokenRenewalTimeout
  authContext = new AuthenticationContext(adalConfig)
  return authContext
}
export const getAuthContext = () => authContext