import React, { useEffect, useRef, forwardRef } from 'react'
import { Input } from '../elements'
import { Key } from '../../../utils/key-util';

export default props => {
  const { children, onSelect, selected, checkable=false, checked, onCheck, actived, onNavigate=()=>{}} = props
  const itemRef = useRef({})

  useEffect(() => { 
    if(actived && itemRef.current){
      itemRef.current.focus()
      itemRef.current.scrollIntoView(false)
    }
  }, [actived])
  // useEffect(() => { selected && itemRef.current.focus() }, [selected])

  // const onClick = event => {
  //   // skip checkbox
  //   if (checkable && event.target.getAttribute("type") == 'checkbox') {
  //     return;
  //   }
  //   onSelect(event)
  // }
  const onKeyDown = event => {
    if (event.keyCode === Key.ENTER) {
      onSelect(event)
    } else {
      onNavigate(event)
    }
  }

  return <div className='list-item' ref={itemRef} tabIndex="0" onKeyDown={onKeyDown}>
    {checkable && <div className='item-checkbox'><Input type='checkbox' aria-label={children()} checked={checked} onChange={onCheck}/></div>}
    <div className={checkable ? 'item-with-checkbox': 'item'} tabIndex="0" onClick={onSelect}>
      {children()}
    </div>
  </div>
}