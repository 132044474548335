import React, { Component } from 'react'

export default class PressHold extends Component {  
  constructor(props, ...rest) {
    super(props, ...rest)
    this.handleButtonPress = this.handleButtonPress.bind(this)
    this.handleButtonRelease = this.handleButtonRelease.bind(this)
  }

  handleButtonPress (action) {
    this.buttonPressTimer = setInterval(action, 100);
  }

  handleButtonRelease () {
    clearInterval(this.buttonPressTimer);
  }

  render() {
    const { styles, className, action } = this.props
    return <div className={className} 
      style={styles}
      onMouseDown={()=>this.handleButtonPress(action)} 
      onMouseUp={this.handleButtonRelease} 
      onMouseLeave={this.handleButtonRelease}/>
  }
}