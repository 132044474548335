import React, { useState, useEffect, forwardRef, useRef } from 'react'
import newId from '../../utils/new-id'

import './animate-input.scss'

export default forwardRef((props, ref) => {
    const { label, value, labelledBy, className, extra, ...rest } = props
    const [id, setId] = useState('')
    const _ref = ref || useRef({})
    useEffect(() => { 
        setId(newId())
    }, [])

    useEffect(() => { 
        if (_ref.current) {
            _ref.current.value = value || ''
        } 
    }, [value, _ref])

    return (
        <div className={`animate-input ${className}`}>
            <label className='sr-only' htmlFor={id}>{label}</label>
            <input {...rest} ref={_ref} aria-labelledby={labelledBy} id={id}/> {extra}
            <span className="underline-animation"></span>
        </div>
    )
})