import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import DropDownMenu from './dropdown/dropdown-menu'
import AnimateInput from './animate-input'
import './typing-input.scss'
      
export default forwardRef((props, ref) =>  {
  const { value, labelledBy, label, placeholder, autoFocus, children, onChange=()=>{}, menuStyle, styles, extra, extra2, onClick=()=>{},onDropMenuHide=()=>{}, ...rest } = props
  const dropRef = useRef({})
  const inputRef = useRef({})

  const api = () => ({
    setValue: v => inputRef.current.value=v,
    getValue: () => inputRef.current.value,
    hideDropMenu: restoreFocus =>dropRef.current.hideDropMenu(restoreFocus),
    showDropMenu: () =>dropRef.current.showDropMenu(),
    isToggleOn: () =>dropRef.current.isToggleOn()
  })
  useImperativeHandle(ref, api)

  useEffect(() => {
    if (value) inputRef.current.value = value
  }, [value])

  useEffect(() => {
    if (autoFocus) {
      setTimeout(()=>inputRef.current && inputRef.current.focus())
    }
  }, [autoFocus])

  const onInput = event =>{
    onChange(event)
    if(!!!event.target.value){
      dropRef.current.hideDropMenu()
      return
    }
    dropRef.current.showDropMenu()
  }
  const onInputClick = event => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
  console.log('typing-input.render')
  return (
    <div className='typing-input'>
      <div className='row'>
        <DropDownMenu scrollable focusable={true} spaceDown={false} ref={dropRef} menuStyle={{maxHeight: '300px', top: '25px', ...menuStyle}} onDropMenuHide={onDropMenuHide} tabbable={false}
          display={<>
          <AnimateInput ref={inputRef} label={label} onClick={onInputClick} onChange={onInput} placeholder ={placeholder} style={styles} extra={extra2} {...rest}/>
          {extra}
        </>}>
        {children}</DropDownMenu>
      </div>
    </div>
  )
})