import React, { useRef, useState, forwardRef, useImperativeHandle} from 'react'
import DropDown from './dropdown-base'
import Scrollbar from '../common/scroll-bar'
import {Input} from '../common/elements'
import './dropdown.scss'
export default forwardRef((props, ref) =>  {
  const {items, keyName='code', labelName='label', onSelect=()=>{}, ...rest}=props
  const [selectedItems, setSelectedItems] = useState([])
  const wrapperRef = useRef({}), selectedItemsRef = useRef([])

  useImperativeHandle(ref, () => ({
		showDropMenu(e){ wrapperRef.current.showDropMenu(e) },
    hideDropMenu(e){ wrapperRef.current.hideDropMenu(e) },
    clear() { 
      setSelectedItems([])
      selectedItemsRef.current = []
    },
    setValues(v) {
      let values = v.split(',')
      let selected = items.filter(e => values.includes(e[keyName]))
      setSelectedItems(selected)
      selectedItemsRef.current = selected
      onSelect(selected)
    }
  }))
  
  const onKeyDown = (e, item) => {
    if(e.keyCode === 13 || e.keyCode === 32){
      if (item) {
        e.preventDefault()
        e.stopPropagation()
        toggleSelection(item)
      } else {
        onClick(e)
      }
    } else if (e.keyCode === 40) {   // Down
      e.preventDefault()
      if(e.target.className === 'dropdown-display'){
        e.target.parentNode.querySelector('.dropdown-menu').children[0].focus()
      }else if(e.target.nextElementSibling){
        e.target.nextElementSibling.focus()
      }
    } else if (e.keyCode === 38) { // Up
      e.preventDefault();
      if(e.target.previousElementSibling){
        e.target.previousElementSibling.focus()
      }
    }
  }

  const onClick = () =>{
    if(!wrapperRef.current.isToggleOn()){
      wrapperRef.current.showDropMenu()
    }
  }

  const toggleSelection = item => {
    if (item[keyName] === '-1' && !selectedItems.some(e => e[keyName] === '-1')) {
      setSelectedItems([item])
      selectedItemsRef.current = [item]
    } else if (selectedItems.some(e => e[keyName] === item[keyName])) {
      let items = selectedItems.filter(e => e[keyName] !== item[keyName])
      setSelectedItems(items)
      selectedItemsRef.current = items
    } else {
      let items = [...selectedItems.filter(e => e[keyName] !== '-1'), item]
      setSelectedItems(items)
      selectedItemsRef.current = items
    }
  }

  const onDropMenuHide = () => {
      onSelect(selectedItemsRef.current)
  }

  console.log("dropdonw-multi render", selectedItems)
  return (
    <DropDown ref={wrapperRef} {...rest} onKeyDown={onKeyDown} onClick={onClick} onDropMenuHide={onDropMenuHide}>
      <Scrollbar>
      {
          items && items.map((item, i) =>
            <div className="dropdown-item" key={i} tabIndex="0" onKeyDown={e=>onKeyDown(e, item)}>
                <Input type='checkbox' label="select item" tabIndex="-1" checked={selectedItems.some(si => si[keyName]===item[keyName])} onChange={()=>toggleSelection(item)}/>
                <span className={`${item.disabled? 'disabled': ''}`} onClick={()=>toggleSelection(item)}>{item[labelName]}</span>
            </div>
          )
      }
      </Scrollbar>
    </DropDown>
  )
})