import React, {useState, useEffect} from 'react'
import {  Key } from '../../utils'
import { Div, Span } from './elements'
import DropDownMenu  from './dropdown/dropdown-menu'
import './gadget2.scss'

export default props => {
  const { collapsable, readOnly, headerText, addActions=[], updateActions=[], children, multi, menuStyle, collapsed = false } = props
  const [collapse, setCollapse] = useState(false)

  useEffect(()=> {
    collapsed && onCollapse()
  }, [])

  const onCollapse = () => setCollapse(!collapse)

  const onKeyDown = (e, action) => {
      if(e.keyCode === Key.ENTER){
          action()
      }
  }
  return (
    <div className='gadget2'>
        <div className="gadget-header"> 
            <span className="header-title">
                {collapsable && <Span className={`collapser fa fa-chevron-${!collapse? 'down' : 'right'}`} onClick={onCollapse}></Span>}
        
                <span className="title" style={{paddingLeft: !collapsable ? '20px': '5px'}}>{headerText}</span>

                {!readOnly && <>
                    {addActions.length == 1 && <Span style={{color: 'grey'}} className='fa fa-plus-circle header-action' onKeyDown={e=>onKeyDown(e, addActions[0].action)} onClick={addActions[0].action}/>}
                    {!multi && updateActions.length == 1 && <Span style={{color: 'grey'}} className='fa fa-cogs header-action' onKeyDown={e=>onKeyDown(e, updateActions[0].action)}  onClick={updateActions[0].action}/>}
                </>
                }
            </span>
            <span className="header-spacer"></span>
       
            {!readOnly && <>
                {addActions.length > 1 && <span className="header-actions">
                    <DropDownMenu display={<div><span style={{color: 'grey'}} className='fa fa-plus-circle'/></div>}>
                        {addActions.map((a, i)=><Div skipFocus className='dropdown-item' key={i} onClick={a.action}>{a.label}</Div>)}
                    </DropDownMenu>
                    </span>
                }

                {(multi && updateActions.length > 0 || updateActions.length > 1) && <span className="header-actions">
                    <DropDownMenu display={<span className="fa fa-ellipsis-h" style={{marginLeft: '10px'}} ></span>} menuStyle={menuStyle}>
                        {updateActions.map((a, i)=><Div skipFocus className='dropdown-item' key={i} onClick={a.action}>{a.label}</Div>)}
                    </DropDownMenu>
                    </span>
                }
            </>}
        </div>

        {!collapse && <div className='gadget-container'>{children}</div>}
    </div>
  )
}